import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SEO from 'components/seo';
import HeroSection from 'components/HeroSection';

const CrashedPage = ({
  seo,
  title,
  buttonText,
  buttonPath,
  description,
  titleFullWidth,
}) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "street.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title={seo} />
      <div className="bannerWrapper">
        <HeroSection
          image={data?.file?.childImageSharp}
          title={title}
          leftButtons={
            buttonText && [
              {
                title: buttonText,
                path: buttonPath,
              },
            ]
          }
          description={description}
          titleFullWidth={titleFullWidth}
        />
      </div>
    </>
  );
};

export default CrashedPage;
